<!-- The site header. -->

<template>
  <header class="siteheader">
    <div id="container-left"></div>

    <div id="container-center">
      <div>
        <div class="title">
          <router-link to="/">
            <h1 class="display-4" :class="$route.name == 'home' ? 'home' : ''">{{ PROJECT_DISPLAY_NAME }}</h1>
          </router-link>
        </div>
        <SearchBar v-if="$route.name != 'home'"/>
      </div>
    </div>
    <div id="container-right">
      <div class="menu">
        <input
          type="checkbox"
          name="menu--toggle"
          id="menu--toggle"
          class="menu--toggle"
        />
        <label for="menu--toggle" class="menu--burger">
          <span></span>
          <span></span>
          <span></span>
        </label>

        <div class="menu--content">
          <nav>
            <ul class="menu">
              <li><router-link to="/woordenlijst">Bladeren</router-link></li>
              <li><router-link to="/over">Over het project</router-link></li>
              <li><router-link to="/help">Help</router-link></li>
            </ul>
          </nav>
        </div>

        <div class="menu-backdop"></div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { SUGGEST_URL, PROJECT_DISPLAY_NAME } from "@/constants";
import { debounce } from "@/util/util";
import SearchBar from "@/generic/components/SearchBar.vue";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        searchInput: InstanceType<typeof HTMLInputElement>;
      };
    }
  >
).extend({
  components: {
    SearchBar,
  },

  data() {
    return {
      search: "",
      PROJECT_DISPLAY_NAME,
      lastPasted: "",
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.siteheader {
  display: flex;
  position: relative;
  background-color: $header-color;
  color: black;
  text-align: center;
  margin: 0;
  padding: 0.7em 1em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1200px) {
    padding: 1em;
  }

  #container-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  #container-center {
    display: flex;
    flex: 3;
    justify-content: center;
  }

  #container-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .title {
    display: inline-block;

    a {
      outline: none;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    h1 {
      color: black;
      text-decoration: none;
      font-family: "schoolboek", "Helvetica Neue", sans-serif;
      font-weight: 400;
      font-size: 28pt;
      margin: 0;
      padding: 0 0 0.2em 0;

      &.home {
        padding: 1.4rem 0 1.4rem 0;
      }
    }

    @media screen and (max-width: 1010px) {
      top: 0;
      left: 6em;
      margin: 0;
      transform: none;

      h1 {
        font-size: 18pt;
      }
    }
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;

    // Menu met 'over het project', etc.
    ul.menu {
      display: flex;
      flex: 0 1 auto;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        padding: 0 1vw;

        a {
          white-space: nowrap;
          font-family: "schoolboek", "Helvetica Neue", sans-serif;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .form-inline {
    display: block;
    width: 30vw;
    min-width: 30rem;
    max-width: 40rem;

    .zoek-in {
      justify-content: center;
    }

    @media screen and (max-width: 600px) {
      width: auto;
      min-width: auto;
    }
  }

  div.menu {
    .menu--toggle {
      display: none;
    }

    .menu--burger {
      display: none;
    }

    @media screen and (max-width: 1060px) {
      .menu--burger {
        display: block;
        position: absolute;
        top: 2rem;
        right: 2rem;
      }

      .menu--toggle:checked ~ .menu--content {
        display: block;

        ul {
          flex-direction: column;
          margin-top: 1rem;
        }
      }

      .menu--content {
        display: none;
      }
    }

    .menu--burger {
      width: 2rem;
      height: 2rem;
      transform: rotate(0deg);
      transition: 0.1s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 0.2rem;
        width: 100%;
        background: $black;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.15s ease-in-out;
      }

      span:nth-child(1) {
        top: 0.25rem;
        transform-origin: left center;
      }

      span:nth-child(2) {
        top: 0.9rem;
        transform-origin: left center;
      }

      span:nth-child(3) {
        top: 1.55rem;
        transform-origin: left center;
      }
    }

    .menu--toggle:checked ~ .menu--burger {
      span:nth-child(1) {
        transform: rotate(45deg);
        top: 0.2rem;
        left: 0.3rem;
      }

      span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
        top: 1.65rem;
        left: 0.3rem;
      }
    }
  }

  @media (max-width: 1060px) {
    flex-direction: column;
    padding-right: 6rem;
  }
}
</style>
