<template>
  <div class='app' :class="{ ['show-'+settings.showSubject]: settings.showSubject.length }" v-if="!isIE()">
    <IvdntHeader />
    <MainHeader />
    <LemmaList/>
    <div class='content'>
      <router-view/>
      <MainFooter />
    </div>
  </div>
  <div v-else class='no-ie'>
    <h1>Helaas, deze browser is niet geschikt.</h1>
    <p>
      U gebruikt een browser die verouderd is (Internet Explorer). Deze applicatie werkt alleen in moderne 
      browsers zoals <a href='https://www.google.nl/intl/nl/chrome/'>Google Chrome</a>, 
      <a href='https://www.microsoft.com/en-us/edge'>Microsoft Edge</a>, 
      <a href='https://www.mozilla.org/nl/firefox/new/'>Mozilla Firefox</a> of 
      <a href='https://www.apple.com/safari/'>Apple Safari</a>. Probeer het alstublieft 
      opnieuw in een van deze browsers.
    </p>
  </div>
</template>

<script langs="ts">
// @ is an alias to /src
import IvdntHeader from '@/components/IvdntHeader.vue'
import MainHeader from '@/components/MainHeader.vue'
import LemmaList from '@/components/LemmaList.vue'
import MainFooter from '@/generic/components/MainFooter.vue'

export default {
  components: {
    IvdntHeader,
    MainHeader,
    LemmaList,
    MainFooter
  },

  computed: {
    article() { return this.$store.state.article; },
    settings() { return { showSubject: '' }; },
  },

  methods: {
    isIE() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return userAgent.indexOf("msie ") > 0 || userAgent.indexOf("trident/") > 0;
    },
  },

};

</script>

<style lang="scss">

@import "@/scss/_variables.scss";
@import "@/scss/tooltip.scss";
//@import "@/assets/fonts/schoolboek/schoolboek.scss";

@font-face {
    font-family: 'schoolboek';

    src: url('./assets/fonts/schoolboek/Schoolboek-Regular.eot');
    src: url('./assets/fonts/schoolboek/Schoolboek-Regular.eot?#iefix') format('embedded-opentype'), 
         url('./assets/fonts/schoolboek/Schoolboek-Regular.woff') format('woff'), 
         url('./assets/fonts/schoolboek/Schoolboek-Regular.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

.no-ie {
  font-size: 120%;
  max-width: 19cm;
  h1 { font-size: 130%; }
  padding: 1em;
}

// Page grid layout
.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-template-columns: minmax(25rem, $lemmalist-width) 1fr;
  grid-template-areas: 
    "ivdntheader ivdntheader"
    "header    header"
    "lemmalist content";

  @media screen and (max-width: 980px) {
    grid-template-rows: auto auto minmax(0, 1fr);
    grid-template-columns: 1fr;
    grid-template-areas: 
      "ivdntheader"
      "header"
      "content";

    & > .lemmalist--container { display: none; }
    .legend { display: none; }
  }

  .ivdntheader {
    grid-area: ivdntheader;
  }

  .siteheader {
    grid-area: header;
  }

  & > .lemmalist--container {
    border-right: 5px solid $primary;
  }

  .lemmalist--container {
    grid-area: lemmalist;
    overflow: auto;
  }

  .content {
    grid-area: content;
    overflow: auto;

    display: flex;
    flex-direction: column;

    & > *:nth-child(1) {
      flex: 1 0 auto;
    }

    footer {
      flex-shrink: 0;
    }
  }
}

// Discrete lemmalist page for mobile
.content > .lemmalist {
  margin: 0;
  background-color: inherit;
}


// Generic styles

body {
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 21px;
  background-color: $white;
}

h1, h2, h3, h4, h5, h6, input, select {
  font-family: 'schoolboek', 'Helvetica Neue', sans-serif;
}

a {
  color: $link-color;
  text-decoration: underline;
}

blockquote {
    padding-left: 2em;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.error {
  color: red;
  font-size: 20pt;
  font-weight: bold;
  border: 2px solid red;
  border-radius: 10px;
  padding: 10px;
}

.main-content {
  margin: 1em;
}

.info-icon {
  &::after {
    position: relative;
    top: -1px;
    display: inline-block;
    margin-left: 0.5em;

    width: 1.3em;
    height: 1.3em;
    text-align: center;
    font-size: 60%;
    font-weight: bold;
    color: $info-icon-color;
    border: 1px solid $info-icon-color;
    border-radius: 50%;
    //background-color: $info-icon-color;
    opacity: 0.6;
    content: "i";
  }
  cursor: pointer;
}

h2 {
  margin-bottom: 0.5em;
}

a {
  color: $black;
  text-decoration: underline;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

</style>

