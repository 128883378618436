<template>
  <div class="api main-content">
    <section>
    <h1>API</h1>
    <p>
      De informatie die op deze website getoond wordt, is ook beschikbaar via
      een API. Deze pagina is bedoeld voor ontwikkelaars die daarvan gebruik
      willen maken.
    </p>
    
    <p>Voor gebruik van de API dient een formeel verzoek ingediend te worden met uw contactgegevens bij <a href="mailto:servicedesk@ivdnt.org">servicedesk@ivdnt.org</a>.</p>
    
    <p>De informatie kan worden opgevraagd via HTTP-verzoeken en zal worden teruggestuurd in JSON.</p>
    
    <p>Basis-URL: <span class="url">https://pinkhof.ivdnt.org/api/pinkhof/v1.0/</span></p>
    </section>
    

    <section>
      <h2>Zoeken</h2>
      <div class="url">
        <span class="method">GET</span>
        <p>search?q.&lt;veldnaam&gt;=&lt;zoekterm&gt;</p>
      </div>
      <table>
        <tr>
          <th scope="col">Parameter</th>
          <th scope="col">Standaardwaarde</th>
          <th scope="col">Beschrijving</th>
        </tr>
        <tr>
          <td>q.&lt;veldnaam&gt;</td>
          <td></td>
          <td>
            De zoekterm. Voeg de veldnaam toe om in dat veld te zoeken. Meerdere
            parameters mogelijk. Waarde mag wildcards (*) bevatten.
          </td>
        </tr>
        <tr>
          <td>rows</td>
          <td>20</td>
          <td>
            (optioneel) Het aantal rijen dat het resultaten maximaal mag bevatten. Maximaal 100.
          </td>
        </tr>
      </table>
      <h3>Voorbeeld</h3>
      <p><pre>https://pinkhof.ivdnt.org/api/pinkhof/v1.0/search?q.lemma-tokenized=test&q.variant-tokenized=*test*&rows=10</pre></p>
      <pre><code>{
  "summary": {
    "params": {
      "q.lemma-tokenized": "test",
      "q.variant-tokenized": "*test*",
      "rows": "10",
    },
    "numFound": 415,
    "start": 0,
  },
  "results": [
    {
      "pid": "6078acd9uec.NL01",
      "title": "test",
      "lemma": "test",
      "lemma-suggest": ["test"],
      "lemma-sort": "test",
      "definition": [
        "procedure of onderzoek ten behoeve van diagnostiek; vb. COVID-19-test",
      ],
      "definition-trunc": [
        "procedure of onderzoek ten behoeve van diagnostiek; vb. COVID-19-test",
      ],
      "seeAlso-name": ["proef", "toets"],
      "seeAlso-target": ["6078acd9ov1.NL01", "663b92d74v.NL01"],
      "xml": '<conceptEntry><langSec xml:lang="nl"><termSec><term>test</term><descripGrp><descrip type="definition">procedure of onderzoek ten behoeve van diagnostiek; vb. COVID-19-test</descrip><ref type="zie ook" target="6078acd9ov1.NL01">proef</ref><ref type="zie ook" target="663b92d74v.NL01">toets</ref><ref type="" target="75d661e33.NL01">testen</ref></descripGrp></termSec></langSec></conceptEntry>',
      "_version_": 1718492906196566026,
    },
    ...
  ],
}</code></pre>

    </section>

    <section>
      <h2>Informatie per document</h2>
      <div class="url">
        <span class="method">GET</span>
        <p>docs/&lt;pid&gt;</p>
      </div>
      <table>
        <tr>
          <th scope="col">Parameter</th>
          <th scope="col">Standaardwaarde</th>
          <th scope="col">Beschrijving</th>
        </tr>
        <tr>
          <td>&lt;pid&gt;</td>
          <td></td>
          <td>
            De identifier van het document (te vinden via /search).
          </td>
        </tr>
      </table>

      <h3>Beschikbare velden</h3>
      <p>Per document zijn de volgende velden beschikbaar:</p>
      <table>
        <tr>
          <th scope="col">Parameter</th>
          <th scope="col">Beschrijving</th>
        </tr>
        <tr>
          <td>pid</td>
          <td>
            Identifier van het document.
          </td>
        </tr>
        <tr>
          <td>title</td>
          <td>
            Leesbare titel, zoals weergegeven boven het document.
          </td>
        </tr>
        <tr>
          <td>lemma</td>
          <td>
            Het hele lemma. Meestal gelijk aan title.
          </td>
        </tr>  
        <tr>
          <td>lemma-suggest</td>
          <td>
            Lemma zoals verwerkt voor betere autocomplete-suggesties. Voor intern gebruik.
          </td>
        </tr>  
        <tr>
          <td>lemma-sort</td>
          <td>
            Lemma ontdaan van alle apostrophes om het sorteren te vereenvoudigen.
          </td>
        </tr>  
        <tr>
          <td>definition</td>
          <td>
            Definitie(s) van de term.
          </td>
        </tr>  
        <tr>
          <td>definition-trunc</td>
          <td>
            Definitie(s) ingekort tot maximaal 100 tekens voor preview-doeleinden.
          </td>
        </tr>  
        <tr>
          <td>see-name</td>
          <td>
            Namen van de 'zie'-verwijzingen. Volgorde correspondeert met see-target.
          </td>
        </tr>  
        <tr>
          <td>see-target</td>
          <td>
            PIDs van de 'zie'-verwijzingen.
          </td>
        </tr>  
        <tr>
          <td>seeAlso-target</td>
          <td>
            PIDs van de 'zie ook'-verwijzingen. Volgorde correspondeert met seeAlso-target.
          </td>
        </tr>  
        <tr>
          <td>synonym-name</td>
          <td>
            Synonymen van de term.
          </td>
        </tr>  
        <tr>
          <td>xml</td>
          <td>
            De oorspronkelijke xml-structuur van het document, voor het indexeren.
          </td>
        </tr>  
        <tr>
          <td>_version_</td>
          <td>
            Intern versienummer.
          </td>
        </tr>  
        <tr>
          <td>xslt_result</td>
          <td>
            HTML-weergave zoals gebruikt op de website.
          </td>
        </tr>  
      </table>

      <h3>Voorbeeld</h3>
      <p><pre>https://pinkhof.ivdnt.org/api/pinkhof/v1.0/docs/6078acd91no.NL01</pre></p>
      <pre><code>{
  "pid": "6078acd91no.NL01", 
  "title": "anti-ds-DNA", 
  "lemma": "anti-ds-DNA", 
  "lemma-suggest": [
    "anti-ds-DNA"
  ], 
  "lemma-sort": "anti-ds-DNA", 
  "definition": [
    "antinucleaire antistof, gericht tegen dubbelstrengs DNA"
  ], 
  "definition-trunc": [
    "antinucleaire antistof, gericht tegen dubbelstrengs DNA"
  ], 
  "seeAlso-name": [
    "DNA"
  ], 
  "seeAlso-target": [
    "6078acd98kh.NL01"
  ], 
  "synonym-name": [
    "anti-ds-DNA-antistof"
  ], 
  "xml": "...", 
  "_version_": 1718492903954710529, 
  "xslt_result": "..."
}</code></pre>
    </section>
  </div>
</template>

<script lang='ts'>
import Vue from "vue";

export default Vue.extend({});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.main-content {
  margin: 2em auto;
  padding: 0 1em;

  section {
    margin-bottom: 3rem;

    .url {
      font-family: "Courier New", Courier, monospace;
      font-size: 1rem;

      * {
        display: inline-block;
      }

      .method {
        margin-right: 1rem;
        padding: 0 0.4rem;
        background-color: $grey-lightest;
        border: 1px solid #ddd;
        color: $int-red;
        font-size: 1rem;
      }
    }

    pre {
      padding: 1rem;
      background-color: $grey-lightest;
      font-family: "Courier New", Courier, monospace;
      font-size: 1rem;
    }

    table {
      margin-bottom: 2rem;

      tr {
        border-bottom: 1px solid $primary-darkest;

        & > * {
          padding: 0.5rem 1rem;
        }

        td:first-child {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>