<template>
  <div>
    <ul
      v-if="showSuggestions && (showLoader || suggestions.length > 0)"
      class="suggestion-list"
    >
      <span v-if="suggestions.length < 0 && showLoader">Laden...</span>
      <li
        v-show="suggestions.length > 0"
        v-for="(suggestion, index) in suggestions"
        :key="suggestion"
        :data-index="index"
        @mousedown.stop="chooseOption(suggestion)"
        @mouseover="highlight(index + 1)"
        :class="{ selected: selectedSuggestionElementIndex == index }"
        v-html="suggestion"
      ></li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { SUGGEST_URL } from "@/constants";
import { debounce } from "@/util/util";

export default Vue.extend({
  props: {
    searchString: String,
    active: Boolean,
    bus: Vue,
  },

  data() {
    return {
      suggestions: [],
      showSuggestions: false,
      showLoader: false,
      selectedSuggestionIndex: 0,
      selectedSuggestion: null,
    };
  },

  watch: {
    searchString: debounce(function (
      this: any,
      e: { target: HTMLInputElement }
    ) {
      this.suggestions = [];

      if (this.active) {
        this.showSuggestions = this.searchString !== "";
        // User typed something. Autosuggest.
        this.getSuggestions();
      }
    },
    200),
  },

  computed: {
    selectedSuggestionElementIndex(): number {
      return this.selectedSuggestionIndex - 1;
    },
  },

  mounted() {
    this.bus.$on("keyup", (e: KeyboardEvent) => {
      this.handleKeyup(e);
    });

    this.bus.$on("focus", (e: KeyboardEvent) => {
      this.showSuggestions = true;
    });

    this.bus.$on("blur", (e: KeyboardEvent) => {
      this.showSuggestions = false;
    });
  },

  methods: {
    chooseOption(suggestion: string) {
      this.$emit("chooseOption", {
        suggestion: this.stripHTMLTags(suggestion),
      });
      this.cancelSuggest();
    },

    cancelSuggest() {
      this.showSuggestions = false;
      this.showLoader = false;
    },

    highlight(index: number) {
      if (index == -1) return;
      this.selectedSuggestionIndex = index;
    },

    type(index: number, text: string) {
      this.highlight(index);
      this.bus.$emit("writeSuggestion", text);
    },

    handleKeyup(e: KeyboardEvent) {
      if (e.key == "Escape") this.cancelSuggest();

      if (e.key == "ArrowUp" || e.key == "ArrowDown") {
        // replace input value, but don't update suggestions
        if (this.suggestions.length == 0) return;

        if (this.showSuggestions == false) {
          this.showSuggestions = true;
          return;
        }

        // scroll through the items
        let suggestList = [this.searchString, ...this.suggestions];
        let direction = e.key == "ArrowDown" ? 1 : -1;
        let l = suggestList.length;
        let suggestListIndex =
          (((this.selectedSuggestionIndex + direction) % l) + l) % l; // Javascript negative modulus workaround

        let selected = suggestList[suggestListIndex];
        let text = this.stripHTMLTags(selected);

        this.type(suggestListIndex, text);
      }
    },

    getSuggestions() {
      if (
        this.searchString == this.$route.params.title ||
        this.searchString == this.$route.query.q ||
        this.searchString == this.$route.query.from
      )
        return;

      let loaderTimer = setTimeout(() => {
        this.showLoader = true;
      }, 500);

      this.selectedSuggestionIndex = 0;
      Vue.axios
        .get(SUGGEST_URL + "?q=" + this.searchString)
        .then((response) => {
          this.suggestions = response.data.suggestions;

          if (this.suggestions.length == 0) this.showSuggestions = false;
          else this.showSuggestions = true;
          this.showLoader = false;
          clearTimeout(loaderTimer);
        });
    },

    stripHTMLTags(input: string) {
      if (input == "" || input === undefined) return input;
      return input.replace(/(<([^>]+)>)/gi, "");
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

ul.suggestion-list {
  position: absolute;
  background-color: $white;
  width: 100%;
  padding: 0;
  text-align: left;
  border: 1px solid $grey;
  box-shadow: 1px 2px 6px 1px $grey-lighter;
  z-index: 100;

  li {
    padding: 0 0.75em;
    list-style-type: none;
    cursor: pointer;
    font-family: "schoolboek", "Helvetica Neue", sans-serif;
    font-size: 1rem;

    &.selected {
      background-color: $grey-lightest;
    }
  }

  span {
    padding: 0 0.5em;
  }
}
</style>
