<template>
  <div>
    <Alphabet />
    <div class="content">
      <h1>{{ $route.params.letter }}</h1>
      <span class="subtitle">{{ selectedLemmas.length }} termen</span>

      <b-spinner v-if="loading" label="Spinning" class="spinner"></b-spinner>
      <template v-else>
        <ul class="lemmagroup--list">
          <li v-for="lemma in selectedLemmas" :key="lemma.pid">
            <a
              :href="slugify(lemma.title, lemma.pid)"
              @click.prevent="
                $router.push({ path: slugify(lemma.title, lemma.pid) })
              "
              :id="lemma.pid"
              v-tooltip.top="{
                content: () => {
                  return getPreview(lemma.pid);
                },
                loadingContent: '<i>Laden...</i>',
                delay: 400,
              }"
            >
              {{ lemma.title }}
            </a>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { PREVIEW_URL } from "@/constants";
import Alphabet from "@/generic/components/AlphabetList.vue";
import { slugify } from "@/util/util";

export default Vue.extend({
  name: "BrowseLetter",
  components: {
    Alphabet,
  },
  computed: {
    loading() {
      return this.$store.state.lemmalist.loading;
    },
    lemmas() {
      return this.$store.state.lemmalist.lemmas;
    },
    selectedLemmas() {
      let lemmas = this.lemmas as {}[];
      let param = this.$route.params.letter;

      return lemmas.filter((lemma: any) => {
        let firstChar = lemma.title.charAt(0).toLowerCase();

        if (param == "0") {
          return !firstChar.match(/[a-zA-Z'‘-]/i);
        }

        let i = 0;
        while (firstChar == "'" || firstChar == "‘" || firstChar == "-") {
          firstChar = lemma.title.charAt(i).toLowerCase();
          i++;
        }

        return firstChar == param;
      });
    },
  },
  methods: {
    slugify,

    async getPreview(pid: string) {
      let response = undefined;
      try {
        // Load list
        const opt = { params: { q: pid } };
        response = await Vue.axios.get(PREVIEW_URL, opt);
        let data = response.data.preview;
        let def = "<i>Geen definitie</i>";
        if (data["definition-trunc"] != undefined)
          def = data["definition-trunc"];

        return `<h2>${data.title}</h2><p>${def}</p>`;
      } catch (err) {
        return "<i>Kon voorbeeld niet laden.</i>";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.content {
  padding: 6rem 2rem 2rem 2rem;
}

h1 {
  text-transform: uppercase;
}

.subtitle {
  color: $grey;
  font-variant: small-caps;
}

.lemmagroup--list {
  list-style: square;
  column-count: 4;
  column-gap: 2rem;
  column-width: 10rem;
  margin-top: 2rem;

  li a {
    width: 10rem;
    overflow-wrap: break-word;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>