<template>
  <div class="main-content center-content">

    <div class="centered">

      <h3>Pagina niet gevonden. Controleer a.u.b. het adres.</h3>

    </div>

  </div>
</template>

<script lang='ts'>
import Vue from 'vue';

export default Vue.extend({

});
</script>

<style scoped lang="scss">

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .centered {
    max-width: 19cm;
    margin: 0 auto;
    flex: 0 0 auto;
  }
}

</style>