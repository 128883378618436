<template>
  <footer>
    <div>
      <router-link to="/over">Meer informatie over {{ PROJECT_DISPLAY_NAME }}</router-link><br>
      <router-link to="/api-docs">API</router-link> | Suggesties of vragen? Stuur een e-mail naar <a :href="`mailto:${CONTACT_EMAIL}`">{{ CONTACT_EMAIL }}</a><br>
      © INT {{ current_year }}, alle rechten voorbehouden.
    </div>
  </footer>
</template>

<script lang="ts">
import Vue from 'vue';
import { PROJECT_DISPLAY_NAME, CONTACT_EMAIL } from '@/constants';

export default Vue.extend({
  data() {
    return {
      PROJECT_DISPLAY_NAME,
      CONTACT_EMAIL,
      current_year: 2024,
    };
  },
  mounted() {
    this.current_year = new Date().getFullYear();
  },
});
</script>

<style scoped lang="scss">

@import "@/scss/_variables.scss";

footer {
  padding: 1em;
  font-size: 1.2rem;
  background-color: $footer-color; //$bg-light;

  a { 
    font-weight: bold; 
    color: black; 
  }
}

</style>
