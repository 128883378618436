var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Alphabet'),_c('div',{staticClass:"content"},[_c('h1',[_vm._v(_vm._s(_vm.$route.params.letter))]),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.selectedLemmas.length)+" termen")]),(_vm.loading)?_c('b-spinner',{staticClass:"spinner",attrs:{"label":"Spinning"}}):[_c('ul',{staticClass:"lemmagroup--list"},_vm._l((_vm.selectedLemmas),function(lemma){return _c('li',{key:lemma.pid},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: () => {
                return _vm.getPreview(lemma.pid);
              },
              loadingContent: '<i>Laden...</i>',
              delay: 400,
            }),expression:"{\n              content: () => {\n                return getPreview(lemma.pid);\n              },\n              loadingContent: '<i>Laden...</i>',\n              delay: 400,\n            }",modifiers:{"top":true}}],attrs:{"href":_vm.slugify(lemma.title, lemma.pid),"id":lemma.pid},on:{"click":function($event){$event.preventDefault();_vm.$router.push({ path: _vm.slugify(lemma.title, lemma.pid) })}}},[_vm._v(" "+_vm._s(lemma.title)+" ")])])}),0)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }