<template>
  <div class="alphabet" :class="{ vertical: vertical }">
    <ul>
      <li
        v-for="letter in '0abcdefghijklmnopqrstuvwxyz'.split('')"
        :key="letter"
      >
        <template v-if="callback">
          <a href="" @click.prevent="callback(letter)">
            {{ letter }}
          </a>
        </template>
        <template v-else>
          <router-link :to="prefix + letter">
            {{ letter }}
          </router-link>
        </template>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    prefix: {
      type: String,
      default: "",
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.alphabet ul {
  background-color: $primary-darker;
  padding: 0;
  margin: 0;
  list-style: none;

  li a {
    display: block;
    width: 2rem;
    height: 2rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 2rem;
    font-family: "schoolboek", "Helvetica Neue", sans-serif;
    font-size: 1.2rem;
    color: $white;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: $primary;
    }
  }
}

.alphabet:not(.vertical) ul {
  display: flex;
  position: fixed;
  flex-wrap: wrap;
}

.alphabet.vertical {
  overflow-y: auto;
  width: 5rem;

  ul {
    display: flex;
    width: 100%;
    flex-direction: column;

    li {
      display: flex;
      justify-content: center;
    }
  }
}
</style>