<template>
  <div>
    <Alphabet prefix="woordenlijst/" />
    <div class="content">
      <h2>Bladeren</h2>
      <p>Totaal aantal termen: {{ lemmas.length }}</p>

      <b-spinner v-if="loading" label="Spinning" class="spinner"></b-spinner>
      <template v-else>
        <div class="lemmagroups">
          <router-link
            :to="key"
            append
            :id="key"
            class="lemmagroups-group"
            v-for="(group, key) in lemmaGroups"
            :key="key"
          >
            <div class="group-name">
              {{ key }}
            </div>
            <div class="count">
              {{ group.length }}
            </div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Alphabet from "@/generic/components/AlphabetList.vue";
import { slugify } from "@/util/util";

export default Vue.extend({
  name: "BrowseAll",
  components: {
    Alphabet,
  },
  computed: {
    loading() {
      return this.$store.state.lemmalist.loading;
    },
    lemmas() {
      return this.$store.state.lemmalist.lemmas;
    },
    lemmaGroups() {
      let groups: { [key: string]: Array<{ [key: string]: string }> } = {};

      this.lemmas.forEach((lemma: { [key: string]: string }) => {
        let firstChar = lemma.title.charAt(0).toLowerCase();
        var groupName = firstChar;

        if (!firstChar.match(/[a-zA-Z'‘-]/i)) {
          groupName = "0";
        } else {
          let i = 0;
          while (groupName == "'" || groupName == "‘" || groupName == "-") {
            groupName = lemma.title.charAt(i).toLowerCase();
            i++;
          }
        }

        if (!groups[groupName]) groups[groupName] = [];

        groups[groupName].push({ title: lemma.title, pid: lemma.pid });
      });
      return groups;
    },
  },
  methods: {
    slugify,
  },
});
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.content {
  padding: 6rem 2rem 2rem 2rem;
}

.lemmagroups {
  display: block;
  column-width: 14rem;
  column-gap: 4rem;

  .lemmagroups-group {
    display: flex;
    width: 100%;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    break-inside: avoid;
    border-bottom: 1px solid $black;

    .group-name {
      text-transform: uppercase;
    }

    &:hover {
      background-color: $primary-lightest;
    }
  }
}
a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>