const SOLR_CORE_NAME = process.env.VUE_APP_SOLR_CORE_NAME;
export const CONTACT_EMAIL = 'servicedesk@ivdnt.org';

export const PROJECT_DISPLAY_NAME = process.env.VUE_APP_PROJECT_DISPLAY_NAME as string;

const BACKEND_URL_DEV  = 'http://localhost:8090';
const BACKEND_URL_PROD = '/api';
const API_VERSION_STRING = 'v1.0'
const REAL_BACKEND_URL = (process.env.NODE_ENV === 'development' ? BACKEND_URL_DEV : BACKEND_URL_PROD);

export const LIST_URL = `${REAL_BACKEND_URL}/${SOLR_CORE_NAME}/${API_VERSION_STRING}/list`;
export const SEARCH_URL = `${REAL_BACKEND_URL}/${SOLR_CORE_NAME}/${API_VERSION_STRING}/search`;
export const DOCS_URL = `${REAL_BACKEND_URL}/${SOLR_CORE_NAME}/${API_VERSION_STRING}/docs`;
export const SUGGEST_URL = `${REAL_BACKEND_URL}/${SOLR_CORE_NAME}/${API_VERSION_STRING}/suggest`;
export const PREVIEW_URL = `${REAL_BACKEND_URL}/${SOLR_CORE_NAME}/${API_VERSION_STRING}/preview`;
export const MOLEX_URL = REAL_BACKEND_URL + "/spelling";


