<!-- The lemma list, shown on the left or on a separate lemma list page (mobile). -->

<template>
  <div ref="lemmalist" class="lemmalist--container">
    <Alphabet vertical :callback="scrollToLetter" />
    <div class="lemmalist">
      <b-spinner v-if="loading" label="Spinning" class="spinner"></b-spinner>
      <template v-else-if="!error">
        <RecycleScroller
          class="scroller"
          ref="scroller"
          :items="lemmas"
          :item-size="50"
          keyField="pid"
          v-slot="{ item }"
        >
          <div class="item">
            <router-link
              :to="slugify(item.title, item.pid)"
              :title="item.title"
            >
              {{ item.title ? item.title : `[pid:${item.pid}]` }}
            </router-link>
          </div>
        </RecycleScroller>
      </template>
      <ErrorAlert :error="error" />
    </div>
  </div>
</template>

<script lang='ts'>
import Vue, { VueConstructor } from "vue";
import { ErrorDisplay } from "../util/util";
import ErrorAlert from "@/generic/components/ErrorAlert.vue";
import Alphabet from "@/generic/components/AlphabetList.vue";
import { slugify } from "@/util/util";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        lemmalist: InstanceType<typeof HTMLElement>;
      };
    }
  >
).extend({
  components: {
    ErrorAlert,
    Alphabet,
  },

  computed: {
    lemmas() {
      return this.$store.state.lemmalist.lemmas;
    },
    loading() {
      return this.$store.state.lemmalist.loading;
    },
    error(): ErrorDisplay {
      return this.$store.state.lemmalist.error;
    },
    currentDocPid() {
      return this.$store.state.article.pid;
    },
  },

  watch: {
    lemmas(): void {
      this.ensureCurrentLemmaVisible();
    },
    currentDocPid(): void {
      this.ensureCurrentLemmaVisible();
    },
    $route(): void {
      this.ensureCurrentLemmaVisible();
    },
  },

  methods: {
    slugify,
    ensureCurrentLemmaVisible() {
      requestAnimationFrame(() => {
        if (!this.currentDocPid || !this.lemmas) return;
        const ll = this.$refs.lemmalist;
        const rectLemmalist = ll.getBoundingClientRect();
        const curr = ll.querySelector<HTMLElement>("ul.lemmas li.current");
        if (curr) {
          const rectLemma = curr.getBoundingClientRect();
          const lemmaVisible =
            rectLemma.top >= rectLemmalist.top &&
            rectLemma.bottom <= rectLemmalist.bottom;
          if (!lemmaVisible) {
            curr.scrollIntoView({ block: "center" });
          }
        }
      });
    },
    scrollToLetter(letter: string) {
      let index = this.lemmas.findIndex((lemma: any) => {
        if (lemma.title.toLowerCase().startsWith(letter)) return true;
      });
      let scroller: any = this.$refs.scroller;
      scroller.scrollToItem(index);
    },
  },
});
</script>

<style lang="scss">
// WebKit hides scrollbars behind elements with absolute position.
// Hardware accelerated CSS prevents that, so let's trigger that.
.vue-recycle-scroller__item-wrapper {
  transform: translate3d(0, 0, 0);
}
</style>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.lemmalist--container {
  display: flex;
  flex-direction: row;
  background-color: $lemmalist-bgcolor;

  .lemmalist {
    width: 100%;

    .scroller {
      height: 100%;

      .item {
        display: flex;
        padding-left: 1rem;
        height: 50px;

        a {
          display: block;
          height: 100%;
          width: 100%;
          line-height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-decoration: none;
        }
      }

      .hover .item {
        background-color: $primary-lightest;
        
        a {
          text-decoration: underline;
        }
      }
    }

    .spinner {
      margin: 1rem;
    }

    ul {
      padding: 0;

      li:nth-child(odd) {
        background-color: $grey-lightest;
      }

      li {
        list-style-type: none;
        scroll-margin-top: 1rem;

        &.current {
          background-color: $lemmalist-selected-bgcolor;
        }

        &:hover:not(.current) {
          background-color: $primary-lighter;
        }

        a {
          display: block;
          width: 100%;
          padding: 0.5rem 1rem;
        }

        .icon {
          margin-left: 0.5em;
        }
      }
    }
  }
}
</style>
