<template>
  <div class="home main-content center-content">
    <div class="centered">
      <p>Doorzoek het Pinkhof Geneeskundig woordenboek</p>
      <SearchBar />
      <p>
        of
        <router-link to="/woordenlijst">blader door alle woorden</router-link>
      </p>
    </div>
  </div>
</template>

<script lang='ts'>
import Vue from "vue";
import SearchBar from "@/generic/components/SearchBar.vue";

export default Vue.extend({
  components: {
    SearchBar,
  },

  data() {
    return {
      lemmalistVisible: true,
    };
  },

  mounted() {
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.lemmalistVisible = window.matchMedia("(min-width: 980px)").matches;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

p.remark {
  margin: 0;
  font-style: italic;
  color: $black-lightest;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .centered {
    max-width: 19cm;
    margin: 0 auto;
    flex: 0 0 auto;
    text-align: center;
  }
}

figure {
  max-width: 12cm;
  text-align: center;

  figcaption {
    margin-top: 1em;
    font-style: italic;
  }
}
</style>