import { render, staticRenderFns } from "./MainFooter.vue?vue&type=template&id=ba6fb22c&scoped=true"
import script from "./MainFooter.vue?vue&type=script&lang=ts"
export * from "./MainFooter.vue?vue&type=script&lang=ts"
import style0 from "./MainFooter.vue?vue&type=style&index=0&id=ba6fb22c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba6fb22c",
  null
  
)

export default component.exports