import { Lemma } from "@/types/lemmalist";

export function dutchCompare(a: string, b: string): number {
  return a.localeCompare(b, "nl-NL", {
    caseFirst: "false", // (use the locale's default)
    ignorePunctuation: true, //
    sensitivity: "base", // ignore case and accents
  });
}

export function compareLemmas(a: Lemma, b: Lemma): number {
  let al = a.title || "";
  if (!al) al = "zzz:" + a.pid; // sort at end
  let bl = b.title || "";
  if (!bl) {
    bl = "zzz" + b.pid; // sort at end
  }

  return dutchCompare(al, bl);
}

export interface ErrorType {
  message: string | null;
  response: {
    status: number;
    data: {
      error: {
        message: string;
      };
    } | null;
  } | null;
}

const ERROR_TRANSLATION: Record<string, string> = {
  "Network Error": "Netwerkfout",
};

export interface ErrorDisplay {
  message: string;
  details: string;
}

export function handleAjaxError(
  err: ErrorType,
  friendlyError?: string | undefined
): ErrorDisplay {
  let msg = "Onbekende fout of zoeksysteem niet bereikbaar";
  let details = "";
  if (err.response) {
    if (err.response.status == 404) {
      msg = "Niet gevonden";
    } else {
      window.console.log("Error response", err.response.data);
      msg = friendlyError ? friendlyError : err.response!.data!.error.message;
      details = friendlyError ? err.response!.data!.error.message : "";
    }
  } else if (err.message) {
    window.console.log("Error", err);
    msg = friendlyError ? friendlyError : err.message;
    details = friendlyError ? err.message : "";
  }

  return {
    message: ERROR_TRANSLATION[msg] || msg,
    details: ERROR_TRANSLATION[details] || details,
  };
}

export const debounce = (func: Function, delay: number) => {
  let debounceTimer: number;
  return function(this: any, ...args : any) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export function cleanupExtraSpaces(str: string) {
  let r = str;
  r = r.replace(/ ([;:,.’”)%])|([‘(„]) /g, "$1$2"); // e.g. one_; two
  r = r.replace(/" ([^"]+) "/g, '"$1"').replace(/' ([^']+) '/g, "'$1'"); // e.g. '_hello_'
  r = r.replace(/ (['"]+\))|(\(['"]+) /g, "$1$2"); // e.g. (''_hello
  r = r.replace(/([^s]') s/g, "$1s"); // e.g. video'_s
  r = r.replace(/ (['"]+,)|(,,) /g, "$1$2"); // e.g. mandaat_'', aldus
  return r;
}

export function slugify(title: string, pid: string) {
  return `/docs/${encodeURIComponent(title)}/${encodeURIComponent(pid)}`;
}
