import Vue from 'vue'
import Vuex from 'vuex'
import { handleAjaxError, ErrorDisplay, ErrorType } from '@/util/util'
import { SEARCH_URL, DOCS_URL } from '@/constants'
import { ArticleState } from '@/types/article'

export default {
    state: {
      pid: '',
      title: '',
      html: '',
      redirect: {},
      loading: false,
      error: null,
    } as ArticleState,
    mutations: {
      articleLoading(state: ArticleState, data: { pid: string }) {
        state.pid = data.pid;
        state.title = '';
        state.html = '';
        state.redirect = {target: '', name: ''};
        state.loading = true;
        state.error = null;
      },
      articleLoaded(state: ArticleState, data: { 
          title: string, 
          partOfSpeech: string, 
          samengesteld: string, 
          reflexiviteit: string, 
          html: string,
          redirect: {[key: string]: string},
      }) {
        state.loading = false;
        state.title = data.title;
        state.html = data.html;
        state.redirect = {name: data.redirect.name, target: data.redirect.target};
      },
      articleLoadError(state: ArticleState, data: { error: ErrorDisplay }) {
        state.title = '';
        state.loading = false;
        state.error = data.error;
      },
      discardArticle(state: ArticleState) {
        state.pid = '';
        state.title = '';
        state.html = '';
        state.redirect = { target: "", name: "" };
        state.loading = false;
        state.error = null;
      },
    },
    actions: {
      async loadArticle(context: any, data: { pid: string }) {
        if (data.pid === context.state.pid) {
          return; // Nothing to
        }

        try {
          context.commit('articleLoading', { pid: data.pid });
  
          // Load HTML
          const articleUrl = `${DOCS_URL}/${data.pid}`;
          const articleResponse = await Vue.axios.get(articleUrl);
          const doc = articleResponse.data;
          const title = doc.title;
          const partOfSpeech = doc['part-of-speech'];
          const samengesteld = doc.samengesteld;
          const reflexiviteit = doc.reflexiviteit;
          const html = doc.xslt_result;
          let redirect = {name: '', target: ''};
          if (doc['redirect-name'] && doc['redirect-target']) {
            redirect = {name: doc['redirect-name'], target: doc['redirect-target']};
          }
          context.commit('articleLoaded', { title, partOfSpeech, samengesteld, reflexiviteit, html, redirect });
        
        } catch (err) {
          context.commit('articleLoadError', { error: handleAjaxError(err as ErrorType, `Kon artikel '${data.pid}' niet laden`) });
        }
  
      }
    },
    getters: {},
  };
