import Vue from 'vue'
import Vuex from 'vuex'

export interface SettingsState {
}

export default {
    state: {
    } as SettingsState,
    mutations: {
    },
    actions: {},
    getters: {},
};
