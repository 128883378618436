import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/HomeView.vue";
import ArticleView from "@/views/ArticleView.vue";
import ResultsView from "@/views/ResultsView.vue";
import Help from "@/views/HelpView.vue";
import About from "@/views/AboutView.vue";
import Browse from "@/views/BrowseAllView.vue";
import BrowseLetter from "@/views/BrowseLetterView.vue";
import ApiDocs from "@/views/ApiDocsView.vue";
import LemmaList from "@/components/LemmaList.vue";
import { PROJECT_DISPLAY_NAME } from "@/constants";
import PageNotFound from "@/views/PageNotFoundView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/over",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "Over het project",
    },
  },
  {
    path: "/docs/:title/:pid",
    component: () => import("../views/ArticleView.vue"),
  },
  {
    path: "/search",
    name: "results",
    component: () => import("../views/ResultsView.vue"),
    meta: {
      title: "Zoekresultaten",
    },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/HelpView.vue"),
    meta: {
      title: "Help",
    },
  },
  {
    path: "/lemmalist",
    name: "lemmalist",
    component: LemmaList,
    meta: {
      title: "Lemmalijst",
    },
  },
  {
    path: "/woordenlijst",
    name: "browse",
    component: Browse,
    meta: {
      title: "Bladeren",
    },
  },
  {
    path: "/woordenlijst/:letter",
    name: "browseLetter",
    component: BrowseLetter,
    meta: {
      title: "Bladeren",
    },
  },
  {
    path: "/bladeren",
    redirect: "/woordenlijst",
  },
  {
    path: "/api-docs",
    name: "api-docs",
    component: ApiDocs,
    meta: {
      title: "API",
    },
  },

  // OLD URLs, STILL SUPPORTED
  {
    path: "/lemma/:title\\::pid",
    redirect: "/docs/:title/:pid",
  },
  {
    path: "/docs/:title",
    component: () => import("../views/ArticleView.vue"),
  },
  {
    path: "/lemma/:pid",
    component: () => import("../views/ArticleView.vue"),
  },

  {
    // will match everything
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  // Scroll to top (or to previous position when navigating history)
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  const appName = PROJECT_DISPLAY_NAME;
  document.title = nearestWithTitle
    ? `${nearestWithTitle.meta.title} - ${appName}`
    : appName;

  next();
});

export default router;
