interface SearchField {
  name: string;
  field: string;
  display: string;
}

export const SEARCH_FIELDS: SearchField[] = [
  {
    name: "lemma",
    field: "lemma-tokenized",
    display: "lemma of variant",
  },
  {
    name: "text",
    field: "xml",
    display: "volledige tekst",
  },

  {
    name: "definitie",
    field: "definition",
    display: "definitie",
  },
];

export function searchFieldByName(name: string): SearchField | undefined {
  const test = name || "lemma";
  return SEARCH_FIELDS.find((f) => f.name === test);
}
