// Import Bootstrap CSS first so we can override it
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import router from '@/router'
import store from '@/store'

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from '@/App.vue'
Vue.use(VueAxios, axios);

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBriefcase, faThLarge, faChartBar, faList, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBriefcase, faThLarge, faChartBar, faList, faTimes, faSearch)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
Vue.component("RecycleScroller", RecycleScroller);

import VTooltip from "v-tooltip";
Vue.use(VTooltip, { defaultClass: "v-tooltip"});

Vue.config.productionTip = false

Vue.filter('capitalize', function (value: any) {
  if (!value)
    return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    this.$store.dispatch('loadLemmaList');
  }
}).$mount('#app')
