<!-- The site header. -->

<template>
  <div class="ivdntheader">
    <div id="container-left">
      <a
        id="int-logo"
        target="_blank"
        href="https://www.ivdnt.org"
        tabindex="-1"
      >
        <img
          src="@/assets/images/ivdnt-logo-1regel.svg"
          title="Instituut voor de Nederlandse taal"
          alt="logo Instituut voor de Nederlandse taal"
        />
      </a>
    </div>

    <div id="container-right">
      <a
        id="int-mainwebsite-link"
        target="_blank"
        href="https://www.ivdnt.org"
        tabindex="-1"
        title="Ga naar ivdnt.org"
        >Ga naar ivdnt.org
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue;
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.ivdntheader {
  display: flex;
  position: relative;
  background-color: $white;
  color: black;
  margin: 0;
  height: 3vw;
  min-height: 2.5rem;
  max-height: 3.5rem;

  justify-content: space-between;
  justify-items: center;
  align-items: center;
  box-shadow: inset 0 -10px 15px -12px #aaa;

  & > * {
    height: 100%;
    padding: 0 1rem 0 0.5rem;
    align-items: center;
  }

  #container-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  #container-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  #int-logo {
    height: 100%;

    img {
      height: 100%;
    }
  }
  #int-mainwebsite-link {
    margin: 0 1rem;
    font-size: 0.8em;
  }
}
</style>
