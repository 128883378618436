<template>
  <div v-if="error && Object.keys(error).length" class='alert alert-warning' role='alert'>
    <h2>{{ error ? error.message : '' }}</h2>
    <p>{{ error ? error.details: '' }}</p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { ErrorDisplay } from '@/util/util';

export default Vue.extend({
  props: {
    error: Object as () => ErrorDisplay|null,
  },
});

</script>