<template>
  <form
    class="search-form form-inline"
    action="/search"
    @submit.prevent="submit"
  >
    <div class="search-input">
      <div class="input-group">
        <div class="input-group-prepend">
          <select
            class="form-control custom-select zoek-in"
            v-model="searchField"
          >
            <option v-for="f in SEARCH_FIELDS" :key="f.name" :value="f.name">
              {{ f.display | capitalize }}
            </option>
          </select>
        </div>
        <input
          ref="searchInput"
          class="form-control search-input"
          type="text"
          tabindex="1"
          name="q"
          data-toggle="tooltip"
          title="Typ een woord"
          placeholder="Zoeken..."
          autocomplete="off"
          v-model="search"
          v-on:keyup="bus.$emit('keyup', $event)"
          v-on:focus="bus.$emit('focus', $event)"
          v-on:blur="bus.$emit('blur', $event)"
        />
        <div class="input-group-append">
          <button
            type="submit"
            class="form-control btn btn-outline-secondary"
            tabindex="2"
            title="Zoeken"
            onclick=""
          >
            <font-awesome-icon icon="search" />
          </button>
        </div>
      </div>
      <Autocomplete
        :active="suggestActive"
        :searchString="suggestString"
        @chooseOption="chooseSuggestion($event.suggestion)"
        :bus="bus"
      />
    </div>
  </form>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import Autocomplete from "@/generic/components/AutocompleteList.vue";
import { SEARCH_FIELDS } from "@/searchFields";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        searchInput: InstanceType<typeof HTMLInputElement>;
      };
    }
  >
).extend({
  name: "SearchBar",
  components: {
    Autocomplete,
  },
  data() {
    return {
      search: "",
      lastPasted: "",
      searchField: "lemma",
      SEARCH_FIELDS,
      bus: new Vue(),
    };
  },
  computed: {
    suggestString: function (): string {
      return this.search;
    },

    suggestActive: function (): boolean {
      return this.searchField === "lemma" && this.lastPasted !== this.search;
    },
  },

  watch: {
    $route() {
      this.onRouteChanged();
    },
  },

  mounted() {
    this.updateSearch();

    this.bus.$on("writeSuggestion", (suggestion: string) => {
      const el = this.$refs.searchInput;
      el.value = suggestion;
    });

    if (this.$route.query.q === undefined) {
      this.$refs.searchInput.focus();
    }
  },

  methods: {
    chooseSuggestion(suggestion: string) {
      this.$refs.searchInput.value = suggestion;
      this.search = suggestion;
      this.submit();
    },

    onRouteChanged() {
      this.updateSearch();
    },

    updateSearch() {
      if (this.$route.query.q) this.search = this.$route.query.q.toString();
    },

    submit() {
      this.search = this.$refs.searchInput.value;
      this.$router.push({
        name: "results",
        query: { q: this.search, in: this.searchField },
      });
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

.search-form {
  flex: 1 auto 1;
  justify-content: center;

  .zoek-in {
    display: flex;
    align-items: center;
  }

  .search-input {
    position: relative;

    input {
      color: black;
    }

    button {
      background-color: $primary-darkest;
      color: $white;
      max-width: 4rem;

      &:hover {
        background-color: $primary-darker;
      }
    }
  }

  .form-control:focus {
    outline: 0 !important;
    border-color: initial;
    box-shadow: none;
  }
}

.home .search-form {
  flex-direction: column;

  & > * {
    margin-bottom: 1rem;
  }

  input {
    width: 35vw;
    max-width: 30rem;
  }
}
</style>