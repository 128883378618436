import Vue from "vue";
import { LIST_URL } from "@/constants";
import { Lemma } from "@/types/lemmalist";
import { handleAjaxError, ErrorDisplay, ErrorType } from '@/util/util';

export interface LemmaListState {
  lemmas: Lemma[];
  error: ErrorDisplay|null;
  loading: boolean;
}

export default {
  state: {
    lemmas: [],
    error: null,
    loading: false,
  } as LemmaListState,
  mutations: {
    lemmaListLoading(state: LemmaListState) {
      state.loading = true;
      state.error = null;
      state.lemmas = [];
    },
    lemmaListLoaded(state: LemmaListState, data: {
      lemmas: Lemma[];
    }) {
      state.loading = false;
      state.lemmas = data.lemmas;
    },
    lemmaListLoadError(state: LemmaListState, data: { error: ErrorDisplay }) {
      state.loading = false;
      state.error = data.error;
    }
  },
  actions: {
    async loadLemmaList(context: any) {
      let response = undefined;
      try {
        context.commit("lemmaListLoading");

        // Load list
        response = await Vue.axios.get(LIST_URL);
        const lemmas = response.data.results;

        context.commit("lemmaListLoaded", { lemmas });
      } catch (err) {
        context.commit("lemmaListLoadError", { error: handleAjaxError(err as ErrorType, "Kon lemmalijst niet laden") });
      }
    }
  },
  getters: {}
};
